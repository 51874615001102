import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ExtensionIcon from '@mui/icons-material/Extension';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';


const AboutUs = () => {
    return (<>
    <Navbar />
    {/* <!-- breadcrumb-area --> */}
        <section className="breadcrumb-area" style={{backgroundImage:"url('https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled%20design%20(68)_Kb0KjHK26.png')"}}>
            <div className="container">
                <div className="breadcrumb__wrapper">
                    <div className="row">
                        <div className="col-xl-6 col-lg-7">
                            <div className="breadcrumb__content">
                                <h2 className="title">ABOUT US</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/" style={{color:"#6610f2"}}>Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">ABOUT US</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-5 position-relative d-none d-lg-block">
                            <div className="breadcrumb__img">
                                <img src="https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Add_a_heading_9_xNv4PyRnO.png?updatedAt=1700396959464" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- breadcrumb-area-end -->

        <!-- services-area --> */}
        <section className="services-area services__bg-color section-pt-120 section-pb-120">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="section__title text-start mb-65">
                            <h3 className="title" style={{fontSize:"35px"}}>Redefining Competitive Sports Through Our Seamless Integration Systems </h3>
                        </div>
                        <div className="services__wrapper">
                            <div className="services__item">
                                <div className="services__icon">
                                    <LocalAtmIcon fontSize='25px' />
                                </div>
                                <div className="services__content">
                                    <h4 className="title"><h5>Single Click Payments</h5></h4>
                                    <p>Be it Visa, MasterCard or UPI, enter paid tourneys with such smooth and ease!</p>
                                </div>
                            </div>
                            <div className="services__item active">
                                <div className="services__icon">
                                    <Diversity1Icon fontSize='25px' />
                                </div>
                                <div className="services__content">
                                    <h4 className="title"><h5>Friendly Support Team</h5></h4>
                                    <p>We go the extra mile for our users  by responding promptly to all your requests.</p>
                                </div>  
                            </div>
                            <div className="services__item">
                                <div className="services__icon">
                                    <ExtensionIcon fontSize='25px' />
                                </div>
                                <div className="services__content">
                                    <h4 className="title"><h5>Uniting Hosts & Players</h5></h4>
                                    <p>Get to know upcoming matches, paid tourneys and more from our events section!</p>
                                </div>
                            </div>
                            <div className="services__item">
                                <div className="services__icon">
                                    <LocalLibraryIcon fontSize='25px' />
                                </div>
                                <div className="services__content">
                                    <h4 className="title"><h5>Nurturing Dreams</h5></h4>
                                    <p>Establish teams, collaborate and compete in our all inclusive and diverse platform.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="services__images">
                            <div className="services__images-item"  style={{borderRadius:"15px"}}>
                                <img src="https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled%20design%20(69)_5x9f2t8E0.png?updatedAt=1700398598090" alt="img"  style={{borderRadius:"15px"}} />
                            </div>
                            <div className="services__images-item active" style={{borderRadius:"15px"}}>
                                <img src="https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/2_Az3iH3VhE.png?updatedAt=1700399038991" alt="img" style={{borderRadius:"15px"}} />
                            </div>
                            <div className="services__images-item" style={{borderRadius:"15px"}}>
                                <img src="https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/3_sreRkDXd2.png?updatedAt=1700399038956" alt="img" style={{borderRadius:"15px"}} />
                            </div>
                            <div className="services__images-item" style={{borderRadius:"15px"}}>
                                <img src="https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled_design_71_OZRX-ZN5_.png?updatedAt=1700399039042" alt="img" style={{borderRadius:"15px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>)
}

export default AboutUs