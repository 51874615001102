import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import Navbar from './Navbar'

const NoPage = () => {

  return (<>
  <Navbar />
<main className="h-[90vh] w-full flex flex-col justify-center items-center" style={{backgroundImage:"url('https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/EVKobWl_XRVaXRTjq.png?updatedAt=1692373391472&ik-s=fbdaf50073804ed9eaa4bba3051f52ed4e6e4e72')",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}> 
	<h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
	{/* <div className="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">
		Page Not Found
	</div> */}
  <h3 className=' text-xl normal-case '>You have wandered off the arena.</h3>
	<button className="mt-5">
      <p className="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring">
        <span
          className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 group-hover:translate-y-0 group-hover:translate-x-0"
        ></span>

        <span className="relative block px-8 py-3 bg-full-transparent border border-current hover:bg-purple-primary">
          <Link to="/" className='text-white uppercase font-black'>Go Home</Link>
        </span>
      </p>
    </button>
</main>
<Footer />
  </>)
}

export default NoPage