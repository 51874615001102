import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import $ from 'jquery'
import { website_name } from '../config/externalURL'
import '../assets/css/navbar.css'
import Navbtns from './Navbtns'
import { Divide } from 'hamburger-react'
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import imagesCDN from '../config/imagesCDN';



const Navbar = () => {

    useEffect(() => {
        // Fade Out Navbar on Scroll
        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) {
                    $('#nav').fadeOut();
                } else {
                    $('#nav').fadeIn();
                }
            });
        });
    }, [])

    useEffect(() => {
        // Mobile Button
        $(document).ready(function () {
            $('.navbar-burger').click(function () {
                $('.navbar-burger').toggleClass('active')
                $('#navlink').toggleClass('active')
            })
        })
    }, [])



    const [open, setOpen] = useState(false);
    const toggleSlider = () => {
        setOpen(!open);
        setDashlistopen(false);
    };



    const [dashlistopen, setDashlistopen] = useState(false);
    useEffect(() => {
        if (dashlistopen) {
            $('#dashlist').removeClass('hidden')
        }
        else {
            $('#dashlist').addClass('hidden')
        }
    }, [dashlistopen])
    const toggleDashList = () => {
        setDashlistopen(!dashlistopen)
    }



    return (
        <section id='naaaavseccc'>
            <nav className="fixed top-0 z-50 px-4 py-4 flex justify-between items-center w-full" id='nav'>
                <a className="text-3xl font-bold leading-none" href="/">
                    <img src={imagesCDN.navlogo} alt={website_name} className='h-10' />
                </a>

                {/* Mobile Nav */}
                <div>
                    <React.Fragment key={'left'}>
                        <div className="lg:hidden text-white">
                            <button className="flex items-center p-3 navtogglebtn z-50" onClick={toggleSlider}>
                                <Divide toggle={open} toggled={open} size={25} />
                            </button>
                        </div>
                        <Drawer anchor="left" open={open} onClose={toggleSlider} >
                            <div style={{ backgroundColor: "#0F161B" }} className='relative h-screen'>
                                <List className='w-56' >
                                    <ListItem className='text-center h-28' >
                                        <a href='/'><img src={imagesCDN.navlogo} alt={website_name} className='h-10' /></a>
                                        <br /><br />
                                    </ListItem>
                                    <Divider style={{ backgroundColor: "#b9b9b9", height: "3px" }} />

                                    <NavLink className="text-md text-gray-400 font-bold" style={{ color: "#7B55FF" }} to="/" id='homenav'>
                                        <ListItem className='text-center h-14 uppercase text-white' >
                                            Home
                                        </ListItem></NavLink>
                                    <Divider style={{ backgroundColor: "#b9b9b9", height: "1px" }} />

                                    <NavLink className="text-md font-bold" onClick={() => toggleDashList()} style={{ color: "#7B55FF" }} id='aboutusnav'>
                                        <ListItem className='text-center h-14 uppercase text-white' >
                                            Tournaments
                                            {dashlistopen ? <KeyboardArrowDownIcon className='ml-auto mr-0' /> : <ChevronRightIcon className='ml-auto mr-0' />}
                                        </ListItem>
                                    </NavLink>
                                    <div className='hidden' id='dashlist'>
                                        <NavLink className="text-md font-bold" to="/dashboard/all-events" style={{ color: "#7B55FF" }} id='aboutusnav'>
                                            <ListItem className='text-center h-10 text-white' style={{ color: "#7B55FF", fontWeight: "bold" }}>
                                                ㅤ<ChevronRightIcon className='text-purple-primary' />All Events
                                            </ListItem></NavLink>
                                        <NavLink className="text-md font-bold" to="/dashboard/active-events" style={{ color: "#7B55FF" }} id='aboutusnav'>
                                            <ListItem className='text-center h-10 text-white' style={{ color: "#7B55FF", fontWeight: "bold" }}>
                                                ㅤ<ChevronRightIcon className='text-purple-primary' />Active Events
                                            </ListItem></NavLink>
                                        <NavLink className="text-md font-bold" to="/dashboard/upcoming-events" style={{ color: "#7B55FF" }} id='aboutusnav'>
                                        <ListItem className='text-center h-10 text-white' style={{ color: "#7B55FF", fontWeight: "bold" }}>
                                            ㅤ<ChevronRightIcon className='text-purple-primary' />Upcoming Events
                                        </ListItem></NavLink>
                                        <NavLink className="text-md font-bold" to="/dashboard/previous-events" style={{ color: "#7B55FF" }} id='aboutusnav'>
                                        <ListItem className='text-center h-10 text-white' style={{ color: "#7B55FF", fontWeight: "bold" }}>
                                            ㅤ<ChevronRightIcon className='text-purple-primary' />Previous Events
                                        </ListItem></NavLink>
                                        <NavLink className="text-md font-bold" to="/dashboard/organize-event" style={{ color: "#7B55FF" }} id='aboutusnav'>
                                        <ListItem className='text-center h-10 text-white' style={{ color: "#7B55FF", fontWeight: "bold" }}>
                                            ㅤ<ChevronRightIcon className='text-purple-primary' />Organize Event
                                        </ListItem></NavLink>
                                    </div>

                                    <Divider style={{ backgroundColor: "#b9b9b9", height: "1px" }} />

                                    <NavLink className="text-md font-bold" to="/aboutus" style={{ color: "#7B55FF" }} id='aboutusnav'>
                                        <ListItem className='text-center h-14 uppercase text-white' >
                                            About Us
                                        </ListItem></NavLink>
                                    <Divider style={{ backgroundColor: "#b9b9b9", height: "1px" }} />

                                    <NavLink className="text-md font-bold" to="/contactus" style={{ color: "#7B55FF" }} id='contactusnav'>
                                        <ListItem className='text-center h-14 uppercase text-white' >
                                            Contact Us
                                        </ListItem></NavLink>
                                    <Divider style={{ backgroundColor: "#b9b9b9", height: "1px" }} />


                                    <div className='bottom-0 left-0 fixed w-56 items-center'>
                                        <Divider style={{ backgroundColor: "#b9b9b9", height: "1px" }} />
                                        <ListItem className='text-center fixed m-auto mr-0 h-14' >
                                            <Navbtns />
                                        </ListItem>
                                    </div>
                                </List>
                            </div>
                        </Drawer>
                    </React.Fragment>
                </div>


                {/* Desktop Nav */}
                <ul id='navlink' className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
                    <li><NavLink className="text-sm text-gray-400 font-bold uppercase" style={{ color: "#7B55FF" }} to="/" id='homenav'>Home</NavLink></li>
                    <li className="text-gray-300"></li>
                    <li><NavLink className="text-sm font-bold uppercase" to="/dashboard/all-events" style={{ color: "#7B55FF" }} id='aboutusnav'>Tournaments</NavLink></li>
                    <li className="text-gray-300"></li>
                    <li><NavLink className="text-sm font-bold uppercase" to="/aboutus" style={{ color: "#7B55FF" }} id='aboutusnav'>About Us</NavLink></li>
                    <li className="text-gray-300"></li>
                    {/* <li><NavLink className="text-sm font-bold" to="/tournaments" style={{ color: "#7B55FF" }} id='servicesnav'>Tournaments</NavLink></li> */}
                    <li className="text-gray-300"></li>
                    <li><NavLink className="text-sm font-bold uppercase" to="/contact" style={{ color: "#7B55FF" }} id='contactnav'>Contact</NavLink></li>
                </ul>

                {/* Avatar or Login Button */}
                <div className="hidden lg:inline-block ">
                    <Navbtns />
                </div>
            </nav>
        </section>
    )
}

export default Navbar