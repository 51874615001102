import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api_server } from '../../config/externalURL';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

const Tournaments = () => {

    const navigate = useNavigate();

    const [retrycount, setRetrycount] = useState(1); //retry count for server down
    const [retry, setRetry] = useState(false);
    var [value, setValue] = useState([])

    useEffect(() => {
        axios.get(api_server + '/api/user/event/getactiveevents').then((res) => {
            setValue(res.data);
        }).catch((err) => {
            console.log(err);
            toast.remove();
            if (retrycount < 4) {
                toast.remove();
                if (retrycount === 1) {
                    toast.error('Server is down!', {
                        duration: 2000,
                    });
                }
                toast.loading(`Trying to connect to server (${retrycount}/3)...`, {
                    duration: 3000,
                });
                setTimeout(() => {
                    setRetrycount(retrycount + 1);
                    setRetry(!retry);
                }, 3500);

            }
        });
    }, [retry, retrycount, navigate])


    return (<>

        <section className="tournament__list-area section-pb-120 section-pt-120" data-background="https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled_design_72_3kByWy-XW.png?updatedAt=1700585647208" style={{ backgroundImage: "url('https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled_design_72_3kByWy-XW.png?updatedAt=1700585647208')" }}>
            <div className="container">
                <div className="tournament__wrapper">
                    <div className="row align-items-end mb-60">
                        <div className="col-lg-8">
                            <div className="section__title text-center text-lg-start title-shape-none">
                                <span className="sub-title tg__animate-text ready stop"><span style={{ animationDelay: "0s" }}>t</span><span style={{ animationDelay: "0.04s" }}>o</span><span style={{ animationDelay: "0.08s" }}>u</span><span style={{ animationDelay: "0.12s" }}>r</span><span style={{ animationDelay: "0.16s" }}>n</span><span style={{ animationDelay: "0.2s" }}>a</span><span style={{ animationDelay: "0.24s" }}>m</span><span style={{ animationDelay: "0.28s" }}>e</span><span style={{ animationDelay: "0.32s" }}>n</span><span style={{ animationDelay: "0.36s" }}>t</span><span style={{ animationDelay: "0.4s" }}> </span><span style={{ animationDelay: "0.44s" }}>L</span><span style={{ animationDelay: "0.48s" }}>i</span><span style={{ animationDelay: "0.52s" }}>s</span><span style={{ animationDelay: "0.56s" }}>t</span></span>
                                <h3 className="title">Active Tournaments</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="tournament__list-item-wrapper">
                                {
                                    value.map((data) => {
                                        return (
                                            <div className="tournament__list-item wow fadeInUp" data-wow-delay=".2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1116.562" height="163.37" viewBox="0 0 1116.562 163.37">
                                                    <path className="background-path" d="M708,1784l28-27s4.11-5.76,18-6,702-1,702-1a37.989,37.989,0,0,1,16,9c7.47,7.08,37,33,37,33s9.02,9.47,9,18,0,42,0,42-0.19,9.43-11,19-32,30-32,30-5.53,11.76-21,12-985,0-985,0a42.511,42.511,0,0,1-26-13c-11.433-12.14-34-32-34-32s-6.29-5.01-7-17,0-43,0-43-1-5.42,12-18,34-32,34-32,10.4-8.28,19-8,76,0,76,0a44.661,44.661,0,0,1,21,11c9.268,8.95,22,22,22,22Z" transform="translate(-401.563 -1749.875)"></path>
                                                </svg>
                                                <div className="tournament__list-content">
                                                    <div className="tournament__list-thumb">
                                                        <img className='' src={data.eventThumb} alt="thumb" />
                                                    </div>
                                                    <div className="tournament__list-name">
                                                        <h5 className="team-name">{data.eventName}</h5>
                                                        <span className="status">{data.eventType}</span>
                                                    </div>
                                                    <div className="tournament__list-prize">
                                                        <h6 className="title">Reg Fee</h6>
                                                        <span>{data.eventPrice}</span>
                                                    </div>
                                                    <div className="tournament__list-time">
                                                        <h6 className="title font-bold"><i className="fas fa-calendar-alt text-white"></i>{data.eventDate}</h6>
                                                        <i className="fas fa-clock text-white"></i>
                                                        <span>{data.eventTime}</span>
                                                    </div>
                                                    <div className="tournament__list-live">
                                                        <a className='block' href={`/register/event/${data.eventID}`}>JOIN <PersonAddAltRoundedIcon className='text-sm' /> </a>
                                                    </div>
                                                </div>
                                            </div>)
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Toaster toastOptions={{
            style: {
                borderRadius: '10px',
                background: '#6f42c1',
                color: '#fff',
                fontWeight: 'bold',
                marginRight: '40px',
            },
        }}
            position="bottom-right"
            reverseOrder={false}
        />
    </>)
}

export default Tournaments