import React from 'react'
import { Link } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info';
import Navbar from './Navbar';
import Footer from './Footer';

const Contact = () => {
  return (<>  
  <Navbar />
  {/* <!-- breadcrumb-area --> */}
        <section className="breadcrumb-area breadcrumb__hide-img" style={{backgroundImage:"url('https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled_1920_x_565_px_1_dDRO_OCBv.png?updatedAt=1700403888435')"}}>
            <div className="container">
                <div className="breadcrumb__wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb__content">
                                <h2 className="title">Contact us</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/" style={{color:"#7B55FF"}}>Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">contact</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- breadcrumb-area-end --> */}

        {/* <!-- contact-area --> */}
        <section className="contact-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="contact__content">
                            <h2 className="title">CONTACT US</h2>
                            <p>We would love to hear from you.</p>
                            <div className="footer-el-widget">
                                <h4 className="title"><InfoIcon /> information</h4>
                                <ul className="list-wrap">
                                    <li><Link to="tel:9495026454">(+91) 9495026454</Link></li>
                                    <li><Link to="mailto:hello@oasisarena.pro">hello@oasisarena.pro</Link></li>
                                    <li><Link to="https://maps.app.goo.gl/hCuJPTsBoJQSy6gs5" target='_blank' rel='noreferrer'>Attingal, Trivandrum - 695105</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="contact__form-wrap">
                            <form id="contact-form" action="https://themedox.com/demo/mykd/assets/mail.php" method="POST" abineguid="B80F6A7B2F7C4DF18EB8BC6E69BA7AF6">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-grp">
                                            <input name="name" type="text" placeholder="Name *" required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-grp">
                                            <input name="email" type="email" placeholder="Email *" required />
                                        <div id="pwm-inline-icon-66738" className="pwm-field-icon" style={{position:"absolute",width:"18px",height:"18px",minHeight:"0px",minWidth:"0px",zIndex:"2147483645",boxShadow:"none",boxSizing:"content-box",background:"none",border:"none",padding:"0px",cursor:"pointer",outline:"none",marginTop:"-39px",marginLeft: "262px"}}>
                                            <svg  viewBox="0 0 40 64"><g><path style={{display:"inline-block",width:"16px",height:"16px",fill:"rgb(230, 0, 23)",marginTop:"0.5px",position: "absolute",top:"0px",left:"0px"}} d="m20,28.12a33.78,33.78 0 0 1 13.36,2.74a22.18,22.18 0 0 1 0.64,5.32c0,9.43 -5.66,17.81 -14,20.94c-8.34,-3.13 -14,-11.51 -14,-20.94a22.2,22.2 0 0 1 0.64,-5.32a33.78,33.78 0 0 1 13.36,-2.74m0,-28.12c-8.82,0 -14,7.36 -14,16.41l0,5.16c2,-1.2 2,-1.49 5,-2.08l0,-3.08c0,-6.21 2.9,-11.41 8.81,-11.41l0.19,0c6.6,0 9,4.77 9,11.41l0,3.08c3,0.58 3,0.88 5,2.08l0,-5.16c0,-9 -5.18,-16.41 -14,-16.41l0,0zm0,22c-6.39,0 -12.77,0.67 -18.47,4a31.6,31.6 0 0 0 -1.53,9.74c0,13.64 8.52,25 20,28.26c11.48,-3.27 20,-14.63 20,-28.26a31.66,31.66 0 0 0 -1.54,-9.77c-5.69,-3.3 -12.08,-4 -18.47,-4l0,0l0.01,0.03z"></path><path d="m21.23,39.5a2.81,2.81 0 0 0 1.77,-2.59a2.94,2.94 0 0 0 -3,-2.93a3,3 0 0 0 -3,3a2.66,2.66 0 0 0 1.77,2.48l-1.77,4.54l6,0l-1.77,-4.5z"></path></g></svg>
                                            </div></div>                                   
                                    </div>
                                </div>
                                <div className="input-grp message-grp">
                                    <textarea name="message" cols="30" rows="10" placeholder="Enter your message"></textarea>
                                </div>
                                <button className="submit-btn">Submit Now</button>
                            </form>
                            <p className="ajax-response"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- contact-area-end --> */}
        <Footer />
  </>)
}

export default Contact