import React from 'react'
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {

    const navigate = useNavigate(); //for navigation
    //if logged in never show login page
    React.useEffect(() => {
        if (localStorage.getItem('isAuth') === 'true') {
            navigate('/dashboard/active-events');
            console.log('Already Logged In');
        }
    }, [navigate]);

    return (
        <section>
            <div className='text-white'>This feature is currently under development. Contact the administrators to reset the password as of now.</div>
        </section>
    )
}

export default ForgotPassword