import React, { useState } from 'react'
import '../assets/css/signuplogin.css'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Backdrop, CircularProgress } from '@mui/material';
import { Toaster, toast } from 'react-hot-toast';
import { api_server } from '../config/externalURL';
import Navbar from './Navbar';

const Signup = () => {

  const navigate = useNavigate(); //for navigation
  
  const [open, setOpen] = useState(false); //open and close for backdrop
  const [islogin, setislogin] = useState(false); //login and signup toggle
  const { register, handleSubmit } = useForm(); //form handling

  const submitLogin = async (data) => {
    try {
      setOpen(true);
      toast.remove();
      await axios.post(api_server +'/api/user/login', data)
        .then((res) => {
          if (res.data.isAuth === true) {
            localStorage.setItem('isAuth', res.data.isAuth);
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('profilepic', res.data.profilepic);
            localStorage.setItem('sessionid', res.data.sessionid);
            console.log('Login Successful');
            navigate('/dashboard/all-events');
          }
          else {
            setOpen(false);
            toast('Invalid Credentials!',
              {
                icon: '🚫',
                style: {
                  borderRadius: '10px',
                  background: '#6f42c1',
                  color: '#fff',
                  fontWeight: 'bold',
                },
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log('Login Failed');
          setOpen(false);
          toast('Login Failed! Try again...',
            {
              icon: '⚠️',
              style: {
                borderRadius: '10px',
                background: '#6f42c1',
                color: '#fff',
                fontWeight: 'bold',
              },
            }
          );
        });
    }
    catch (error) {
      console.log(error);
      console('Error in Login');
      navigate('/login');
    }
  }


  const submitSignup = async (signupdata) => {
    try {
      setOpen(true);
      await axios.post(api_server + '/api/user/register', signupdata)
        .then((res) => {
          if (res.data === true) {
            toast.success('Account Created. Login to Continue!');
            setislogin(true);
            setOpen(false);
            document.getElementById('login-select').click();
            document.getElementById('usersignupform').reset();
          }
          else if (res.data === "User already exists.") {
            setOpen(false);
            toast('User already exists!',
              {
                icon: '⚠️',
                style: {
                  borderRadius: '10px',
                  background: '#6f42c1',
                  color: '#fff',
                  fontWeight: 'bold',
                },
              }
            );
          }
          else {
            setOpen(false);
            toast('Signup Failed!',
            {
              icon: '🚫',
              style: {
                borderRadius: '10px',
                background: '#6f42c1',
                color: '#fff',
                fontWeight: 'bold',
              },
            }
          );
          }
        })
    }
    catch (error) {
      console.log(error);
      console.log('Error in Signup');
      navigate('/login');
    }
  }


  //if logged in never show login page
  React.useEffect(() => {
    if (localStorage.getItem('isAuth') === 'true') {
      navigate('/dashboard/all-events');
      console.log('Already Logged In');
    }
  }, [navigate]);

  const isAuth = localStorage.getItem('isAuth');
  const isAuthed = isAuth === 'true' ? true : false;


  return (<>
  <Navbar />
    <section className="signupsection">
      {isAuthed ? navigate('/dashboard/all-events') :
        <div className="signuploginmain">

          <input className='signuplogininput' type="checkbox" defaultChecked={islogin} id="chk" aria-hidden="true" />

          <div className="signup">
            <form onSubmit={handleSubmit(submitSignup)} id='usersignupform'>
              <label className='signuploginlabel' for="chk" aria-hidden="true">Sign Up</label>
              <input className='signuplogininput' style={{ height: "40px" }} type="text" name="signupname" {...register('signupname')} placeholder="Full Name" required />
              <input className='signuplogininput' style={{ height: "40px" }} type="email" name="signupemail" {...register('signupemail')} placeholder="Email" required />
              <input className='signuplogininput' style={{ height: "40px" }} type="password" name="signuppassword" {...register('signuppassword')} placeholder="Password" required />
              <button className='signuploginbutton' type='submit'>Sign up</button>
            </form>
          </div>

          <div className="login">
        <form  onSubmit={handleSubmit(submitLogin)}>
          <label className='signuploginlabel' for="chk" id='login-select' aria-hidden="true">Login</label>
          <input className='signuplogininput' style={{height:"40px"}} type="email" name="loginemail" {...register('loginemail')} placeholder="Email" required />
          <input className='signuplogininput' style={{height:"40px"}} type="password" name="loginpassword" {...register('loginpassword')} placeholder="Password" required />
          <button className='signuploginbutton' type='submit'>Login</button>
        </form>
      </div>
        </div>}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster toastOptions={{
        style: {
          borderRadius: '10px',
          background: '#6f42c1',
          color: '#fff',
          fontWeight: 'bold',
        },
      }}
        position="top-center"
        reverseOrder={true}
      />
    </section></>
  )
}


export default Signup