module.exports = {
    favicon : "../../public/favicon.ico",
    navlogo : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/oanavlogo_BtDlHN1gs.png?updatedAt=1692380758412&ik-s=c8b44b55550c3fcc0a7a31ee8a6b61ec4f7baa07",
    mainbg : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/EVKobWl_XRVaXRTjq.png?updatedAt=1692373391472&ik-s=fbdaf50073804ed9eaa4bba3051f52ed4e6e4e72",
    minor_about_bg : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/minoraboutimgbg_Y7XQQ0FOi.png?updatedAt=1692379818820&ik-s=123894534c69e71a2ed1eda4812c99eb5bf53c76",
    minor_about_img : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/purple_jetty.png?updatedAt=1694771655653&ik-s=745c8e14533018109ad871802682a53aa68c4b88",
    minor_about_trophy : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/minorabouttrophy_UoE2tj3BU.png?updatedAt=1692380221000&ik-s=1c26789915d3067ad018ab493a9b3e37e13cedcd",
    back_to_top : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/backtotop_rxDwq9_xY.png?updatedAt=1692617180614&ik-s=846ba7257f68db32cd108fe7f4f96d4e325c9df7",
    match_result_bg : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/result_bg2_LbYlosrHU.png?updatedAt=1694769494567&ik-s=320426b7a0c0cdfd70c73f735c24565c2b0f946b",

    // About Us
    
    aboutus_bg_main : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled%20design%20(68)_Kb0KjHK26.png?updatedAt=1700396925247",
    aboutus_bg2 : "https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/team_bg_oWEhJ9kvc.jpg?updatedAt=1700396607907"
}