import React from 'react'
import './css/sidebar.css'
// import { NavLink } from 'react-router-dom'
import { useState } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import $ from 'jquery';
import { Tooltip, Zoom } from '@mui/material';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import VideogameAssetOffIcon from '@mui/icons-material/VideogameAssetOff';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import ConstructionIcon from '@mui/icons-material/Construction';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        backgroundColor: '#000000',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#000000',
                    marginTop: 'calc(100vh - 70vh)',
                    borderTop: '1px solid #7B2DFD',
                    borderBottom: '1px solid #7B2DFD',
                    borderRight: '0.5px solid #7B2DFD',
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                    height: 'auto',
                },
            },
        },
    },
});

const iconButtonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // This centers the IconButton along the right margin
    padding: '10px', // Optional padding for the header, but it looks good
};

const Sidebar = () => {


    const handleDrawerOpenorClose = () => {
        setOpen(!open);
    };

    const [open, setOpen] = useState(false);
    const [tooltip, setTooltip] = useState("Close Sidebar");
    const [menuitem1, setMenuitem1] = useState("All Events");
    const [menuitem2, setMenuitem2] = useState("Active Events");
    const [menuitem3, setMenuitem3] = useState("Upcoming Events");
    const [menuitem4, setMenuitem4] = useState("Previous Events");
    const [menuitem5, setMenuitem5] = useState("Organize Event");


    useEffect(() => {
        if (open === true) {
            setTooltip("Close Sidebar");
            setMenuitem1("");
            setMenuitem2("");
            setMenuitem3("");
            setMenuitem4("");
            setMenuitem5("");
        } else {
            setTooltip("Open Sidebar");
            setMenuitem1("All Events");
            setMenuitem2("Active Events");
            setMenuitem3("Upcoming Events");
            setMenuitem4("Previous Events");
            setMenuitem5("Organize Event");
        }
    }, [open])


    // sidebar close on scroll if open
    $(document).ready(function () {
        $(window).scroll(function () {
            if (open === true) {
                setOpen(false);
            }
        });
    });

    return (
        <div className='hidden lg:block sidebardrawer'>
            <ThemeProvider theme={theme}>
                <Drawer variant="permanent" open={open}>
                    <List style={iconButtonStyles}>
                        <Tooltip title={tooltip} placement="top" className='cursor-pointer'>
                            <IconButton onClick={handleDrawerOpenorClose} className='hover:text-purple-primary cursor-pointer'>
                                {open ? <Zoom in={open}><KeyboardDoubleArrowLeftIcon className='hover:text-purple-primary cursor-pointer' /></Zoom> : <Zoom in={!open}><KeyboardDoubleArrowRightIcon className='hover:text-purple-primary cursor-pointer' /></Zoom>}
                            </IconButton>
                        </Tooltip>
                    </List>
                    <Divider />
                    <nav id='navlink'>
                        <List>
                            <NavLink to='/dashboard/all-events' activeClassName='active' draggable="false">
                                <Tooltip title={menuitem1} placement="right">
                                    <ListItem key='Dashboard'>
                                        <ListItemIcon>
                                            <VideogameAssetIcon className='dashicon' width={"23px"} height={"23px"} />
                                        </ListItemIcon>
                                        All Events
                                    </ListItem>
                                </Tooltip>
                            </NavLink>

                            <NavLink to='/dashboard/active-events' activeClassName='active' draggable="false">
                                <Tooltip title={menuitem2} placement="right">
                                    <ListItem key='Dashboard'>
                                        <ListItemIcon>
                                            <NotificationImportantIcon className='dashicon' />
                                        </ListItemIcon>
                                        Active Events
                                    </ListItem>
                                </Tooltip>
                            </NavLink>

                            <NavLink to='/dashboard/upcoming-events' activeClassName='active' draggable="false">
                                <Tooltip title={menuitem3} placement="right">
                                    <ListItem key='Dashboard'>
                                        <ListItemIcon>
                                            <UpcomingIcon className='dashicon' />
                                        </ListItemIcon>
                                        Upcoming Events
                                    </ListItem>
                                </Tooltip>
                            </NavLink>

                            <NavLink to='/dashboard/organize-event' activeClassName='active' draggable="false">
                                <Tooltip title={menuitem4} placement="right">
                                    <ListItem key='Dashboard'>
                                        <ListItemIcon>
                                            <VideogameAssetOffIcon className='dashicon' />
                                        </ListItemIcon>
                                        Previous Events
                                    </ListItem>
                                </Tooltip>
                            </NavLink>

                            <NavLink to='/dashboard/organize-event' activeClassName='active' draggable="false">
                                <Tooltip title={menuitem5} placement="right">
                                    <ListItem key='Dashboard'>
                                        <ListItemIcon>
                                            <ConstructionIcon className='dashicon' />
                                        </ListItemIcon>
                                        Organize Event
                                    </ListItem>
                                </Tooltip>
                            </NavLink>
                        </List>
                    </nav>
                </Drawer>
            </ThemeProvider>
        </div>
    )
}

export default Sidebar