import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import 'remixicon/fonts/remixicon.css'
import externalURL from '../../config/externalURL'

const JoinCommunity = () => {
    return (
        
        <section className="video__area video-bg tg-jarallax joincommunity-1" data-background="https://media.discordapp.net/attachments/1047913572203773983/1138854091003531395/Untitled_design_13.png?width=1024&height=488" data-jarallax-original-styles="background-image: url(https://media.discordapp.net/attachments/1047913572203773983/1138854091003531395/Untitled_design_13.png?width=1024&height=488);" style={{ backgroundImage: "url('https://i.imgur.com/lHrm6zj.png')" , }}>
            <div className="container">
                <Parallax speed={20} >
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8 col-md-11">
                        <div className="video__content text-center">
                            <h2 className="title">JOIN THE <span>COMMUNITY</span></h2>
                            <p>Join our discord community and chase your game.</p>
                            <a href={externalURL.discord_url} target="_blank" rel='noreferrer' className="video__btn tg-btn-1 joindc"><span>DISCORD</span></a>
                        </div>
                    </div>
                </div>
                </Parallax>
            </div>
    
        </section>
    )
}

export default JoinCommunity