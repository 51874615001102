import React from 'react'
import { Link } from 'react-router-dom'
import imagesCDN from '../../config/imagesCDN'
import { Parallax } from 'react-scroll-parallax'




const Hero = () => {


  return (
    <Parallax speed={-20} >
    <section className="banner__area banner__padding js-parallax" style={{backgroundImage: `url(${imagesCDN.mainbg})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" , maxHeight:"105vh"}}>
            <div className="banner__bg tg-jarallax" data-background={imagesCDN.mainbg}
                data-jarallax-original-styles="background-image: url('https://i.imgur.com/vf1J8IZ.jpg');">
                <div id="jarallax-container-0 hero-1">
                    <div className='hero-2'></div>
                </div>
            </div>
            <div className="container custom-container">
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-10">
                        <div className="banner__content slider__content text-center">
                            <h2 className="title wow bounceInLeft hero-3" data-wow-delay=".2s">
                                <b >OASIS Arena</b></h2>
                            <p className="wow bounceInLeft hero-4" data-wow-delay=".4s" style={{fontFamily:"Azonix", wordSpacing:"-6px" , letterSpacing:"3px"}}>Your
                                Quest Awaits</p>
                            <div className="banner__btn wow bounceInLeft hero-5" data-wow-delay=".6s">
                                <Link to="/signup" rel='noreferrer' className="tg-btn-1 joindc cursor-pointer"><span>REGISTER</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </Parallax>
  )
}

export default Hero