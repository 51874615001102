// eslint-disable-next-line  @typescript-eslint/no-explicit-any
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import $ from "jquery";
import NoPage from "./components/NoPage";
import Signup from "./components/Signup";
// import UnderConstruction from "./components/UnderConstruction";
import { ParallaxProvider } from "react-scroll-parallax";
import ForgotPassword from "./components/util/ForgotPassword";
import RegForm from "./components/dashboard/main/RegForm";
import { back_to_top, favicon } from "./config/imagesCDN";
import Favicon from "react-favicon";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import PolicyMenu from "./components/policies/PolicyMenu";
import TermsAndConditions from "./components/policies/TermsAndConditions";
import CancellationAndRefund from "./components/policies/CancellationAndRefund";
import ShippingAndDelivery from "./components/policies/ShippingAndDelivery";
import ContactPolicy from "./components/policies/ContactPolicy";
import NowActiveEvents from "./components/dashboard/NowActiveEvents";
import AllEvents from "./components/dashboard/AllEvents";
import UpcomingEvents from "./components/dashboard/UpcomingEvents";

function App() {

  // Scroll to top button
  $(document).ready(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#scroll').fadeIn();
      } else {
        $('#scroll').fadeOut();
      }
    });
    $('#scroll').click(function () {
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
    });
  });

  //Disable context menu
  document.addEventListener('contextmenu', event => event.preventDefault());
  $(document).bind("contextmenu", function (e) {
    return false;
  });
    
  //redirect to dashboard/active-events if logged in
  const Redirdashfn = () => {
    window.location.href = "/dashboard/all-events";
    return null;
  }


  return (
    <div className="cursor-default">
      <Favicon url={favicon} />
      <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          {/* Other pages */}
          <Route path="/aboutus" element={ <AboutUs /> } />
          <Route path="/contact" element={<Contact />} />
          {/* Dashboard */}
          <Route path="/dashboard/all-events" element={<AllEvents />} />
          <Route path="/dashboard/active-events" element={<NowActiveEvents /> } />
          <Route path="/dashboard/upcoming-events" element={<UpcomingEvents />} />
          <Route path="/dashboard" element={<Redirdashfn />} />

          {/* Event Registration */}
          <Route path="/register/event/:eventid" element={<RegForm />} />

          {/* Util */}
          <Route path="/auth/forgotpassword" element={<ForgotPassword />} />

          {/* Policies */}
          <Route path="/policies" element={<PolicyMenu />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/cancellationandrefundpolicy" element={<CancellationAndRefund />} />
          <Route path="/shippinganddeliverypolicy" element={<ShippingAndDelivery />} />
          <Route path="/contactus" element={<ContactPolicy /> } />

          {/* 404 */}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
      </ParallaxProvider>
      <img src={back_to_top} id="scroll" style={{ display: "none" }} alt="back to top"></img>
    </div >
  );
}

export default App;
