import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import SecurityIcon from '@mui/icons-material/Security';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ContactsIcon from '@mui/icons-material/Contacts';
import CancelIcon from '@mui/icons-material/Cancel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const PolicyMenu = () => {
    return (<>
        <Navbar />
        <section className="breadcrumb-area breadcrumb__hide-img" style={{ backgroundImage: "url('https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled_1920_x_565_px_4_L1zXlMYPq.png?updatedAt=1700487938980')" }}>
            <div className="container">
                <div className="breadcrumb__wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb__content">
                                <h2 className="title">Our Policies</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='mt-24 md:mt-32'>
            <div className="container">
                <div className="flex row">
                    <div className="services__wrapper">
                        <div className="services__item">
                            <div className="services__icon">
                                <AutoStoriesIcon fontSize='25px' />    
                            </div>
                            <div className="services__content">
                                <h4 className="title"><h5>Terms And Conditions</h5></h4>
                                <p>Use this website in accordance with our terms and privacy policy.</p><br />
                                <a href="/termsandconditions" draggable="false" className='pl-3 pr-3 pt-2 pb-2 font-bold text-sm bg-purple-primary rounded-full text-white'>Read More</a>
                            </div>
                        </div>
                        <div className="services__item active">
                            <div className="services__icon">
                                <SecurityIcon fontSize='25px' />
                            </div>
                            <div className="services__content">
                                <h4 className="title"><h5>Privacy Policy</h5></h4>
                                <p>This privacy policy sets out how Oasis Arena uses and protects any information that you give Oasis Arena when you use this website.</p><br />
                                <a href="/privacypolicy" draggable="false" className='pl-3 pr-3 pt-2 pb-2 font-bold text-sm bg-purple-primary rounded-full text-white'>Read More</a>
                            </div>
                        </div>
                        <div className="services__item">
                            <div className="services__icon">
                                <CancelIcon fontSize='25px' />
                            </div>
                            <div className="services__content">
                                <h4 className="title"><h5>Cancellation & Refund Policy</h5></h4>
                                <p>Oasis Arena believes in helping its customers as far as possible and has therefore a liberal cancellation policy</p><br />
                                <a href="/cancellationandrefundpolicy" draggable="false" className='pl-3 pr-3 pt-2 pb-2 font-bold text-sm bg-purple-primary rounded-full text-white'>Read More</a>
                            </div>
                        </div>
                        <div className="services__item">
                            <div className="services__icon">
                                <LocalShippingIcon fontSize='25px' />
                            </div>
                            <div className="services__content">
                                <h4 className="title"><h5>Shipping & Delivery Policy</h5></h4>
                                <p>Shipping is not applicable for business.</p><br />
                                <a href="/shippinganddeliverypolicy" draggable="false" className='pl-3 pr-3 pt-2 pb-2 font-bold text-sm bg-purple-primary rounded-full text-white'>Read More</a>
                            </div>
                        </div>
                        <div className="services__item">
                            <div className="services__icon">
                                <ContactsIcon fontSize='25px' />
                            </div>
                            <div className="services__content">
                                <h4 className="title"><h5>Contact Us</h5></h4>
                                <p>For inquiries about our company on the online platform, contact us <a href="/contact" className='text-purple-primary'>here</a>.</p><br />
                                <a href="/contactus" draggable="false" className='pl-3 pr-3 pt-2 pb-2 font-bold text-sm bg-purple-primary rounded-full text-white'>Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br /><br />
        <Footer />
    </>)
}

export default PolicyMenu