import React from 'react'
import '../assets/css/themain.css'
import '../assets/css/plugins.css'
import Hero from './home/Hero'
import MatchResult from './home/MatchResult'
import About from './home/About'
// import UpcomingMatch from './home/UpcomingMatch'
import JoinCommunity from './home/JoinCommunity'
import Navbar from './Navbar'
import Footer from './Footer'
import imagesCDN from '../config/imagesCDN'
import Tournaments from './home/Tournaments'

const Home = () => {
  return (
    <>
    <Navbar />
    <div style={{zIndex:"0"}}>
      <Hero />
      <MatchResult />
      <Tournaments />
      <div className="area-background mainpagebottom-1" data-background={imagesCDN.mainbg}>
        <About />
      </div>
      {/* <UpcomingMatch /> */}
      <JoinCommunity />
    </div>
    <Footer />
    </>
  )
}

export default Home