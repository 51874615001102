import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'

const ShippingAndDelivery = () => {
  return (<><Navbar />
  <section className="breadcrumb-area breadcrumb__hide-img" style={{ backgroundImage: "url('https://ik.imagekit.io/ipuppyyt/Oasis%20Arena/Untitled_1920_x_565_px_4_L1zXlMYPq.png?updatedAt=1700487938980')" }}>
    <div className="container">
      <div className="breadcrumb__wrapper">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb__content">
              <h2 className="title">Shipping And Delivery Policy</h2>
              <p className=' font-bold'>Last updated : November 18 2023</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className='ml-7 mr-7 mt-[50px] mb-20 md:ml-20 md:mr-20 md:mt-24 md:mb-24 lg:ml-32 lg:mr-32 lg:mt-24 lg:mb-24 text-white'>
    <p className='font-bold text-white text-center list-disc'>Shipping is not applicable for business.</p>
  </section>
  <br /><br />
  <Footer />
</>)
}

export default ShippingAndDelivery