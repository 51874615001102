import React from 'react'
import { match_result_bg } from '../../config/imagesCDN'

const MatchResult = () => {
  return (
    <section className="match__result-area">
            <div className="match__result-bg matchresult-1" data-background={match_result_bg}  style={{ backgroundImage: `url(${match_result_bg})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover"}}></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-md-10">
                        <div className="section__title text-center mb-55">
                            <span className="grand__final-place">UPCOMING</span>
                            <h3 className="title">TOURNAMENTS</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h4 className="match__winner-title">Premier league</h4>
                    </div>
                </div>
                <div className="row match__result-wrapper justify-content-center">
                    <div className="col-xl-5 col-sm-6">
                        <div className="match__winner-wrap">
                            <div className="match__winner-info">
                                <h4 className="name">ZENTRIX</h4>
                                <span className="price-amount">ZEN</span>
                            </div>
                            <div className="match__winner-img tg-svg">
                                <div className="team-logo-img">
                                    <a href="/"><img
                                            src="https://i.imgur.com/rfuVODi.png"
                                            alt="img" /></a>
                                </div>
                                <div className="svg-icon" id="svg-3" data-svg-icon="assets/img/icons/win_shape.svg"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                                        viewBox="0 0 135 75">
                                        <path id="rectangle" className="cls-1 matchresult-2"
                                            d="M924,1194H809v75H924s20-37.5,20-37.63C944,1231.5,924,1194,924,1194Z"
                                            transform="translate(-809 -1194)"></path>
                                    </svg></div>
                                <h3 className="match__winner-place">DEF</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-sm-6">
                        <div className="match__winner-wrap">
                            <div className="match__winner-info">
                                <h4 className="name">DEMISE</h4>
                                <span className="price-amount">DMS</span>
                            </div>
                            <div className="match__winner-img tg-svg">
                                <div className="team-logo-img">
                                    <a href="/"><img
                                            src="https://i.imgur.com/TCsDldk.png"
                                            alt="img" /></a>
                                </div>
                                <div className="svg-icon" id="svg-4" data-svg-icon="assets/img/icons/win_shape.svg"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                                        viewBox="0 0 135 75">
                                        <path id="rectangle" className="cls-1 matchresult-3"
                                            d="M924,1194H809v75H924s20-37.5,20-37.63C944,1231.5,924,1194,924,1194Z"
                                            transform="translate(-809 -1194)"></path>
                                    </svg></div>
                                <h3 className="match__winner-place">ATK</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="grand__final">
{/* <!-- Date & Time --> */}    
                            <h6 className="grand__final-date">December 20, 2023 | 7:00 PM</h6>
                            <span className="grand__final-place">VALORANT</span>
{/* <!-- Read More Button --> */}
                             {/* <div className="grand__final-button">
                             <a href="https://discord.gg/kcrmXPZrT6" target="_blank" rel='noreferrer' className="video__btn tg-btn-1 joindc cursor-pointer"><span>🔴 LIVE NOW</span></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default MatchResult