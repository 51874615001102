import React, { useEffect } from 'react'
import $ from 'jquery'
import imagesCDN from '../../config/imagesCDN';
import { next_tournament } from '../../config/externalURL';

const About = () => {


    useEffect(() => {
        var countDownDate = new Date(next_tournament).getTime();

        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0) {
                clearInterval(x);
                $('#timer').html("LIVE NOW");
            }
            else {
                $('#timer').html(days + "d " + hours + "h "
                    + minutes + "m " + seconds + "s ");
            }
        }, 1000);
    }, [])

    return (
        <section className="about__area-two section-pt-160 section-pb-190" style={{ backgroundImage: "url('https://i.imgur.com/zwoCE1J.jpg')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-xl-6 col-lg-6 order-0 order-lg-2">
                        <div className="about__funFact-images">
                            <img src={imagesCDN.minor_about_bg}
                                alt="background" className="bg-shape" />
                            <img src={imagesCDN.minor_about_img} className="main-img cursor-not-allowed"
                                alt="Jett Fly" />
                        </div>
                        <div className="about__funFact-trophy">
                        <div className="icon"><img src={imagesCDN.minor_about_trophy}
                                alt="trophy" /></div>
                            <div className="content">
                                <h5>Tournament</h5>
                                <span style={{ color: "#7B55FF" }}>HOSTING</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-10">
                        <div className="section__title text-start mb-30">
                            <h3 className="title">Ready For a Challenge<span style={{ color: "#7B55FF" }}>?</span></h3>
                        </div>
                        <div className="about__content-two">
                            <p>Join the ultimate competitive experience with our regular tournaments! Register now to showcase your skills, team up with passionate gamers, and compete for glory. Elevate your gameplay, forge new connections, and immerse yourself in the world of tactical FPS excitement. Secure your spot today and let the battles begin!</p>
                        </div>

                        <br /><br />
                        <div className="about__funFact-wrap">
                            <div className="section__title">
                                <span className="grand__final-place">NEXT TOURNAMENT IN</span>
                                <h1 style={{ fontWeight: "bolder" }} id="timer">ㅤ</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About