import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
// import Footer from './Footer'
import { useNavigate } from 'react-router-dom';
import imagesCDN from '../../config/imagesCDN';
import Sidebar from './Sidebar';
import Swal from 'sweetalert2';
import { Animated } from 'react-animated-css';
import axios from 'axios';
import { api_server } from '../../config/externalURL';
import { Backdrop, CircularProgress } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';

const NowActiveEvents = () => {

  const [isLoggedIn, setisLoaded] = useState(true);
  const [animate , setAnimate] = useState(false);
  const [retrycount, setRetrycount] = useState(1); //retry count for server down
  const [retry, setRetry] = useState(false);
  var [value, setValue] = useState([])
  const [loading, setLoading] = useState(true);

  //redirect to login if not logged in
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("isAuth") !== "true" && localStorage.getItem("sessionid") === null) {
      setisLoaded(false);
      Swal.fire({
        theme: 'dark',
        title: 'Please Login',
        text: 'You need to login to access this page',
        icon: 'warning',
        confirmButtonText: 'Login',
        confirmButtonColor: '#6f42c1',
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        stopKeydownPropagation: false,
        reverseButtons: true,
        showCloseButton: true,
        background: '#1f1f1f',
        color: '#fff',
        backdrop: true,
      }).then((result) => {
        navigate('/login');
      })
    }
    else{
      setisLoaded(true);
      setAnimate(true);
    }


  axios.get(api_server + '/api/user/event/getactiveevents').then((res) => {
    setValue(res.data);
    setLoading(false);
}).catch((err) => {
    console.log(err);
    setLoading(true);
    toast.remove();
    if (retrycount < 4) {
        toast.remove();
        if (retrycount === 1) {
            toast.error('Server is down!', {
                duration: 2000,
            });
        }
        toast.loading(`Trying to connect to server (${retrycount}/3)...`, {
            duration: 3000,
        });
        setTimeout(() => {
            setRetrycount(retrycount + 1);
            setRetry(!retry);
        }, 3500);

    }
    else {
        toast.remove();
        Swal.fire({
            theme: 'dark',
            title: 'Server is down!',
            text: 'Please try again later',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            reverseButtons: true,
            stopKeydownPropagation: false,
            confirmButtonText: 'Retry',
            confirmButtonColor: '#7B55FF',
            cancelButtonText: 'Go Home',
            cancelButtonColor: '#d33',
            background: '#1f1f1f',
            color: '#fff',
            backdrop: true,
        }).then((result) => {
            if (result.isConfirmed) {
                setRetrycount(1);
            }
            else {
                navigate('/');
            }
        })
    }

})

}, [retry, retrycount, navigate])



  return (
    <section className='h-screen' style={{ backgroundImage: `url(${imagesCDN.mainbg})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
      {isLoggedIn ?  
      <><Navbar />
      <Sidebar />
      <div className="content sm:ml-0 md:ml-0 lg:ml-0">
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={animate} animationInDuration={1500}>
        

        <section className="nft-item__area">
            <div className="container custom-container">
                <div className="row justify-content-center">
                    {
                        value.map((data) => {
                            return (<div className="col-xxl-4 col-xl-5 col-lg-6 col-md-9" key={data._id}>
                                <div className="nft-item__box">
                                    <div className="nft-item__thumb"><img src={data.imgUrl} alt="img" draggable="false" />
                                    </div>
                                    <div className="nft-item__content">
                                        <h4 className="title">{data.eventName}</h4>
                                        <div className="nft-item__avatar">
                                            <div className="avatar-name">
                                                <h5 className="name">{data.eventDate}<br />
                                                    {data.eventTime} IST
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="nft-item__bid">
                                            <div className="nft-item__price">
                                                {
                                                    data.eventOver ? <p className="currency">Event Over</p> :
                                                        <><p><span className="currency">{data.eventPrice}</span></p>
                                                            <a href={`/register/event/${data.eventID}`} className="bid-btn" draggable="false" id='joinbutton'>JOIN</a></>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })
                    }

                    {/* Loading */}
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Toaster toastOptions={{
                        style: {
                            borderRadius: '10px',
                            background: '#6f42c1',
                            color: '#fff',
                            fontWeight: 'bold',
                            marginRight: '40px',
                        },
                    }}
                        position="bottom-right"
                        reverseOrder={false}
                    />
                </div>
            </div>
        </section>


        </Animated>
      </div></> : <></>}
    </section>
  )
}

export default NowActiveEvents