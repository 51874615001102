import React, { useEffect, useState } from 'react'
import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { api_server } from '../config/externalURL'
import SettingsIcon from '@mui/icons-material/Settings';

const Navbtns = () => {

    const navigate = useNavigate();

    //open and close for backdrop
    const [dropopen, setdropOpen] = useState(false);
    const [navbarUserIsLogged, setnavbarUserIsLogged] = useState(false);

    //navbar dashboard menu control
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (localStorage.getItem("isAuth") === "true") {
            setnavbarUserIsLogged(true);
        }
        //timer for 2 seconds
        setTimeout(() => {
            setdropOpen(false);
        }, 2000);
    }, [])

    //logged in user info
    const loggedInuserName = localStorage.getItem("name");
    const loggedInuserpfp = localStorage.getItem("profilepic");


    //logout function
    const hashedsessionid = localStorage.getItem("sessionid");
    const logoutfn = () => {
        setdropOpen(true);
        axios.post(api_server + '/api/user/logout', { hashedsessionid: hashedsessionid })
            .then((res) => {
                if (res.status === 200) {
                    setnavbarUserIsLogged(false);
                    localStorage.removeItem("isAuth");
                    localStorage.removeItem("name");
                    localStorage.removeItem("profilepic");
                    localStorage.removeItem("sessionid");
                    setdropOpen(false);
                    navigate('/');
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }


    //getting params from url
    // const {id} = useParams();

    return (
        <>
            {navbarUserIsLogged ? (<React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', }}>
                    <Tooltip title="Account" arrow>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        className='cursor-pointer'
                    >
                        <Avatar className='avatar-bg cursor-pointer' alt={loggedInuserName} src={loggedInuserpfp} sx={{ width: 40, height: 40 }} draggable="false" />
                    </IconButton>
                    </Tooltip>
                </Box>
                <div style={{ backgroundColor: "#292929" }}>
                <Menu
                    anchorEl={anchorEl}
                    className='cursor-default'
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 42,
                                height: 42,
                                ml: -0.5
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <MenuItem style={{pointerEvents:"none"}} className='w-56 cursor-default'>
                        <Avatar className='avatar-bg cursor-default' alt={loggedInuserName} src={loggedInuserpfp} sx={{ width: 48, height: 48, mr: 2 }} /><b className='text-lg cursor-default'>{loggedInuserName}</b>
                    </MenuItem>
                    <div className='ml-3 mr-3'><Divider /></div>
                    <MenuItem className='font-semibold cursor-not-allowed' disabled>
                        <div className='flex items-center justify-center w-8 h-8 rounded-full bg-grey-iconbg cursor-not-allowed'><SettingsIcon fontSize="small" /></div> &nbsp; Settings
                    </MenuItem>
                    <MenuItem className='font-semibold cursor-pointer' onClick={logoutfn}>
                    <div className='flex items-center justify-center w-8 h-8 rounded-full bg-grey-iconbg cursor-pointer'><ExitToAppIcon fontSize="small" /></div> &nbsp;Logout
                    </MenuItem>
                </Menu>
                </div>
            </React.Fragment>) : (<Link to="/login" className='cursor-pointer'><div className="navlogbutton cursor-pointer" id="button-5">
                <div className="translate-login cursor-pointer"></div>
                <span className="navlogtext cursor-pointer">LOGIN</span>
            </div></Link>)}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={dropopen}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Navbtns
