import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {

    let year = new Date().getFullYear();

    return (
        <footer style={{
            position: "", width: "100%", right: 0,
            bottom: 0,
            left: 0,
            
        }} className="footer-style-two has-footer-animation footerbordertop fixed inset-x-0 bottom-0">
            <div className="copyright__wrap -style-two">
                <div className="container custom-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="copyright__text text-center text-lg-start">
                                <p>© {year} <Link to="/" draggable="false"><span>Oasis Arena</span></Link>. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="copyright__menu">
                                <ul className="list-wrap d-flex flex-wrap justify-content-center justify-content-lg-end">
                                    <li><Link to="/contact" draggable="false">Contact Us</Link></li>
                                    <li><a href="/policies" draggable="false">Policies</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer