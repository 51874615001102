import React, { useState } from 'react'
import '../assets/css/signuplogin.css'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { api_server } from '../config/externalURL';
import axios from 'axios';
import { Backdrop, CircularProgress } from '@mui/material';
import { Toaster, toast } from 'react-hot-toast';
import { Animated } from 'react-animated-css';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import Navbar from './Navbar';

const Login = () => {

  const navigate = useNavigate(); //for navigation

  const [open, setOpen] = useState(false); //open and close for backdrop
  const [islogin, setislogin] = useState(true); //login and signup toggle
  const [animate, setAnimate] = useState(false); //animation
  const { register, handleSubmit } = useForm(); //form handling

  const submitLogin = async (data) => {
    try {
      setOpen(true);
      toast.remove();
      await axios.post(api_server + '/api/user/login', data)
        .then((res) => {
          if (res.data.isAuth === true) {
            localStorage.setItem('isAuth', res.data.isAuth);
            localStorage.setItem('userid', res.data.userid);
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('profilepic', res.data.profilepic);
            localStorage.setItem('sessionid', res.data.sessionid);
            setOpen(false);
            navigate('/dashboard/all-events');
          }
          else {
            setOpen(false);
            toast('Invalid Credentials!',
              {
                icon: '🚫'
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log('Login Failed');
          setOpen(false);
          toast('Login Failed! Try again...',
            {
              icon: '⚠️'
            }
          );
        });
    }
    catch (error) {
      navigate('/login');
    }
  }


  const submitSignup = async (signupdata) => {
    try {
      setOpen(true);
      await axios.post(api_server + '/api/user/register', signupdata)
        .then((res) => {
          if (res.data === true) {
            toast.success('Account Created. Login to Continue!');
            setislogin(true);
            setOpen(false);
            document.getElementById('login-select').click();
          }
          else if (res.data === "User already exists.") {
            toast('User already exists!',
              {
                icon: '⚠️'
              }
            );
            setOpen(false);
          }
          else {
            toast.error('Signup Failed!');
            setOpen(false);
          }
        })
    }
    catch (error) {
      navigate('/login');
    }
  }


  //if logged in never show login page
  useEffect(() => {
    if (localStorage.getItem('isAuth') === 'true') {
      Swal.fire({
        theme: 'dark',
        title: 'Already Logged In',
        text: 'You are already logged in',
        icon: 'warning',
        confirmButtonText: 'Go to Dashboard',
        confirmButtonColor: '#6f42c1',
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        stopKeydownPropagation: false,
        reverseButtons: true,
        showCloseButton: true,
        background: '#1f1f1f',
        color: '#fff',
        backdrop: true,
      }).then((result) => {
        navigate('/dashboard/all-events');
      })
    }
    else {
      setAnimate(true);
    }
  }, [navigate]);

  const isAuth = localStorage.getItem('isAuth');
  const isAuthed = isAuth === 'true' ? true : false;


  return (<>
  <Navbar />
    <section className="signupsection">
      {isAuthed ? navigate('/dashboard/active-events') :
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={animate} animationInDuration={1500}>
          <div className="signuploginmain">
            <input className='signuplogininput' type="checkbox" defaultChecked={islogin} id="chk" aria-hidden="true" />
            <div className="signup">
              <form onSubmit={handleSubmit(submitSignup)}>
                <label className='signuploginlabel' for="chk" aria-hidden="true">Sign Up</label>
                <input className='signuplogininput' style={{ height: "40px" }} type="text" name="signupname" {...register('signupname')} placeholder="Full Name" required />
                <input className='signuplogininput' style={{ height: "40px" }} type="email" name="signupemail" {...register('signupemail')} placeholder="Email" required />
                <input className='signuplogininput' style={{ height: "40px" }} type="password" name="signuppassword" {...register('signuppassword')} placeholder="Password" required />
                <button className='signuploginbutton' type='submit'>Sign up</button>
              </form>
            </div>

            <div className="login">
              <form onSubmit={handleSubmit(submitLogin)}>
                <label className='signuploginlabel' for="chk" id='login-select' aria-hidden="true">Login</label>
                <input className='signuplogininput' style={{ height: "40px" }} type="email" name="loginemail" {...register('loginemail')} placeholder="Email" required />
                <input className='signuplogininput' style={{ height: "40px" }} type="password" name="loginpassword" {...register('loginpassword')} placeholder="Password" required />
                <button className='signuploginbutton' type='submit'>Login</button>
                <a className='forgotpassword' href="/auth/forgotpassword">Forgot Password?</a>
              </form>
            </div>
          </div>
        </Animated>}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster toastOptions={{
        style: {
          borderRadius: '10px',
          background: '#6f42c1',
          color: '#fff',
          fontWeight: 'bold',
        },
      }}
        position="top-center"
        reverseOrder={true}
      />
    </section></>
  )
}


export default Login