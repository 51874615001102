import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../Navbar';
import imagesCDN from '../../../config/imagesCDN';
import config, { api_server } from '../../../config/externalURL'
import '../css/eventregform.css'
import { Avatar, Backdrop, Box, CircularProgress, Slider } from '@mui/material';
import { Toaster, toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Animated } from 'react-animated-css';



const RegForm = () => {

    const eventid = useParams().eventid;

    //redirect to login if not logged in
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("isAuth") !== "true" && localStorage.getItem("sessionid") === null) {
            navigate('/login');
        }
    }, [navigate])

    //redirect to dashboard if such an event doesn't exist
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios.get(api_server + '/api/user/event/getallevents').then((res) => {
            var flag = false;
            for (var i = 0; i < res.data.length; i++) {
                if (res.data[i].eventID === eventid) {
                    flag = true;
                    setLoading(false);
                    break;
                }
            }
            if (flag === false) {
                navigate('/dashboard/active-events');
            }
        });
    }, [navigate, eventid])




    const marks = [{ value: 0, label: '0' }, { value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }];

    const [noofsubs, setNoOfSubs] = useState(0);
    const [sub1name, setSub1Name] = useState('none');
    const [sub2name, setSub2Name] = useState('none');
    const [sub3name, setSub3Name] = useState('none');
    const substituteEnable = (event, newnoofsubs) => {
        setNoOfSubs(newnoofsubs);
        if (newnoofsubs === 0) {
            setSub1Name('none');
            setSub2Name('none');
            setSub3Name('none');
        }
        else if (newnoofsubs === 1) {
            setSub1Name('block');
            setSub2Name('none');
            setSub3Name('none');
        }
        else if (newnoofsubs === 2) {
            setSub1Name('block');
            setSub2Name('block');
            setSub3Name('none');
        }
        else if (newnoofsubs === 3) {
            setSub1Name('block');
            setSub2Name('block');
            setSub3Name('block');
        }
    }

    const invalidAlert = (alertmsg) => {
        toast.error('Please fill in ' + alertmsg, {
            duration: 2000,
            position: 'top-center',
        });
    }

    const [teamlogoloaded, setTeamLogoLoaded] = useState(false);
    const [therealteamlogo, setTheRealTeamLogo] = useState(null);
    const [teamlogo, setTeamLogo] = useState("Upload Team Logo");
    // don't accept teamlogo if resolution is less than 512x512
    const teamlogoUpload = () => {
        const teamlogo = document.getElementById('teamlogo');
        teamlogo.click();
        teamlogo.addEventListener('change', () => {
            if (teamlogo.files.length === 0) {
                return;
            }
            const file = teamlogo.files[0];
            const filename = file.name;
            if (filename === "" || filename === null || filename === undefined) {
                return;
            }
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const img = new Image();
                img.src = reader.result;
                if (img.src === "" || img.src === null || img.src === undefined) {
                    return;
                }
                img.addEventListener('load', () => {
                    const width = img.width;
                    const height = img.height;
                    if (width === 0 || height === 0) {
                        toast.remove();
                        return;
                    }
                    if ((width < 512 || height < 512) || width !== height) {
                        toast.remove();
                        toast.loading('Checking image...');
                        teamlogo.value = "";
                        setTeamLogo("Upload Team Logo");
                        setTeamLogoLoaded(false);
                        setTimeout(() => {
                            toast.remove();
                            toast.error('Please upload a team logo of resolution 512x512.', {
                                duration: 2000,
                                position: 'top-center',
                            });
                        }, 1000);
                    }
                    else {
                        toast.remove();
                        toast.loading('Checking image...');
                        setTimeout(() => {
                            toast.remove();
                            if (filename !== "" && filename !== null && filename !== undefined) {
                                setTeamLogo("Team Logo : " + filename);
                            }
                            setTeamLogoLoaded(true);
                            toast.success('Team Logo Approved.', {
                                duration: 2000,
                                position: 'top-center',
                            });
                        }, 1000);
                    }
                    // convert image to base64 encoded string
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    const dataurl = canvas.toDataURL('image/png');
                    setTheRealTeamLogo(dataurl);
                })
            })
            reader.readAsDataURL(file);
        })
    }


    const { register, handleSubmit } = useForm();
    const registerEvent = (data) => {
        data.eventid = eventid;
        data.teamlogo = therealteamlogo;
        if (data.fullname === "" || data.fullname === null || data.fullname === undefined) {
            invalidAlert("your fullname.");
            return;
        }
        else if (data.email === "" || data.email === null || data.email === undefined) {
            invalidAlert("your email.");
            return;
        }
        else if (!data.email.includes("@") || !data.email.includes(".")) {
            invalidAlert("a valid email.");
            return;
        }
        else if (data.phone === "" || data.phone === null || data.phone === undefined) {
            invalidAlert("your phone number.");
            return;
        }
        else if (data.teamname === "" || data.teamname === null || data.teamname === undefined) {
            invalidAlert("your team name.");
            return;
        }
        else if (data.teamleaderemail === "" || data.teamleaderemail === null || data.teamleaderemail === undefined) {
            invalidAlert("your team leader email.");
            return;
        }
        else if (!data.teamleaderemail.includes("@") || !data.teamleaderemail.includes(".")) {
            invalidAlert("a valid team leader email.");
            return;
        }
        else if (data.teamlogo === "" || data.teamlogo === null || data.teamlogo === undefined) {
            invalidAlert("your team logo.");
            return;
        }
        else if (data.teamleadername === "" || data.teamleadername === null || data.teamleadername === undefined) {
            invalidAlert("your team leader name.");
            return;
        }
        else if (data.player1name === "" || data.player1name === null || data.player1name === undefined) {
            invalidAlert("player 1 name.");
            return;
        }
        else if (data.player2name === "" || data.player2name === null || data.player2name === undefined) {
            invalidAlert("player 2 name.");
            return;
        }
        else if (data.player3name === "" || data.player3name === null || data.player3name === undefined) {
            invalidAlert("player 3 name.");
            return;
        }
        else if (data.player4name === "" || data.player4name === null || data.player4name === undefined) {
            invalidAlert("player 4 name.");
            return;
        }
        else if (data.player5name === "" || data.player5name === null || data.player5name === undefined) {
            invalidAlert("player 5 name.");
            return;
        }
        if (noofsubs === 1 && data.sub1name === "") {
            invalidAlert("substitute 1 name.");
            return;
        }
        else if (noofsubs === 2 && (data.sub1name === "" || data.sub1name === null || data.sub1name === undefined)) {
            invalidAlert("substitute 1 name.");
            return;
        }
        else if (noofsubs === 2 && (data.sub2name === "" || data.sub2name === null || data.sub2name === undefined)) {
            invalidAlert("substitute 2 name.");
            return;
        }
        else if (noofsubs === 3 && (data.sub1name === "" || data.sub1name === null || data.sub1name === undefined)) {
            invalidAlert("substitute 1 name.");
            return;
        }
        else if (noofsubs === 3 && (data.sub2name === "" || data.sub2name === null || data.sub2name === undefined)) {
            invalidAlert("substitute 2 name.");
            return;
        }
        else if (noofsubs === 3 && (data.sub3name === "" || data.sub3name === null || data.sub3name === undefined)) {
            invalidAlert("substitute 3 name.");
            return;
        }
        axios.post(config.api_server + '/api/user/event/register', data)
            .then((res) => {
                if (res.data === "ALREADY REGISTERED") {
                    toast.error('You have already registered for this event.', {
                        duration: 2000,
                        position: 'top-center',
                    });
                    return;
                }
                else {
                    toast.loading('Starting Payment Process...', {
                        duration: 2000,
                        position: 'top-center',
                    });
                }

                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);

            }).catch((err) => {
                toast.error('Error in registering for the event.', {
                    duration: 2000,
                    position: 'top-center',
                });
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);
            })
    }

    const gotoDash = () => {
        navigate("/dashboard/active-events");
    }

    return (
        <section style={{ backgroundImage: `url(${imagesCDN.mainbg})`, backgroundPosition: "center", backgroundSize: "cover", minHeight: "100vh" }}>
            <Navbar />
            <div className='flex flex-col items-center justify-center h-full'>
                <div className='flex flex-col items-center justify-center w-11/12 md:w-3/4 lg:w-1/2 mt-28 bg-white rounded-lg shadow-lg'>
                    <div className='flex flex-col items-center justify-center w-full p-4'>
                        <h1 className='text-2xl font-bold text-center text-black'>Event Registration Form</h1>
                        <p className='text-sm text-center'>Please fill in the form below to register for the event</p>
                    </div>
                    <div className='flex flex-col items-center justify-center w-full p-4'>
                        <form className='flex flex-col items-center justify-center w-full' id='regform'>

                            {/* Event ID */}
                            <h1 className='text-xl font-bold text-center text-black'>Personal Information</h1>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="fullname" id='fullname' {...register('fullname')} placeholder='Full Name' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Email</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='email' name="email" id='email' {...register('email')} placeholder='Email' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Phone Number</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='number ' name="phone" id='phone' {...register('phone')} placeholder='Phone number' required />
                            </div>
                            <br />
                            <h1 className='text-xl font-bold text-center text-black'>Team Information</h1>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Team Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="teamname" id='teamname' {...register('teamname')} placeholder='Team Name' required />
                            </div>
                            <div className='flex flex-row mt-3 items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600' hidden>Team Logo</label>
                                <button className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400 text-left text-grey-iconbg hover:text-grey-iconbg' type='button' onClick={teamlogoUpload}>{teamlogo}</button>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400 hidden' type='file' accept="image/png, image/jpeg" id='teamlogo' placeholder='Team Logo' required />
                                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={teamlogoloaded}>
                                    <Avatar className='ml-2' src={therealteamlogo} sx={{ width: 40, height: 40 }} hidden={!teamlogoloaded} />
                                </Animated>
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Leader Name (IGL)</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="teamleadername" id='teamleadername' {...register('teamleadername')} placeholder='Leader Name (IGL)' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Team Leader Email</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='email' name="teamleaderemail" id='teamleaderemail' {...register('teamleaderemail')} placeholder='Team Leader Email' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Player 1 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="player1name" id='player1name' {...register('player1name')} placeholder='Player 1 Name' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Player 2 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="player2name" id='player2name' {...register('player2name')} placeholder='Player 2 Name' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Player 3 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="player3name" id='player3name' {...register('player3name')} placeholder='Player 3 Name' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Player 4 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="player4name" id='player4name' {...register('player4name')} placeholder='Player 4 Name' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <label className='text-sm font-bold text-gray-600'>Player 5 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="player5name" id='player5name' {...register('player5name')} placeholder='Player 5 Name' required />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full'>
                                <br />
                                <p className='text-general-grey'>No of substitutes</p>
                                <Box sx={{ width: 300 }}>
                                    <Slider aria-label="Number of players" value={noofsubs} step={1} max={3} marks={marks} onChange={substituteEnable} />
                                </Box>
                            </div>
                            <div className='flex flex-col items-center justify-center w-full' id='sub1-name' style={{ display: sub1name }}>
                                <label className='text-sm font-bold text-gray-600'>Substitute 1 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="sub1name" id='sub1name' {...register('sub1name')} placeholder='Substitute 1 Name' required={true} />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full' id='sub2-name' style={{ display: sub2name }}>
                                <label className='text-sm font-bold text-gray-600'>Substitute 2 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="sub2name" id='sub2name' {...register('sub2name')} placeholder='Substitute 2 Name' required={true} />
                            </div>
                            <div className='flex flex-col items-center justify-center w-full' id='sub3-name' style={{ display: sub3name }}>
                                <label className='text-sm font-bold text-gray-600'>Substitute 3 Name</label>
                                <input className='w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400' type='text' name="sub3name" id='sub3name' {...register('sub3name')} placeholder='Substitute 3 Name' required={true} />
                            </div>
                            <br />

                            <div className='flex flex-row items-center justify-center w-full p-4'>
                                {/* add 2 buttons side by side */}
                                <button className='w-1/2 p-2 text-lg font-bold text-white bg-purple-primary rounded-lg hover:bg-red-300' type='reset' onClick={gotoDash}>Reset</button>
                                <button className='w-1/2 ml-3 p-2 text-lg font-bold text-white bg-purple-primary rounded-lg hover:bg-blue-300' type='submit' onClick={handleSubmit(registerEvent)}>Register</button>
                            </div>
                        </form>
                    </div>
                </div>
                <br /><br /><br /><br />
            </div>
            <Toaster toastOptions={{ style: { borderRadius: '10px', background: '#6f42c1', color: '#fff', fontWeight: 'bold' } }} position="top-center" reverseOrder={true} />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </section>
    )
}

export default RegForm